<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_type_line"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_type_line')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes['cm']"
            :placeholder="$_msgContents('UC.WORD.EXCHANGER')"
            :items="getCmList"
            display-expr="codeNm"
            value-expr="codeValue"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('cm', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="lineGrid" />
    <div>
      <DxPopup
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <DxScrollView width="100%" height="100%">
          <ModalAddLine
            :isOpened="this.modal.isOpened"
            :cmList="this.cmList"
            :partitionList="this.partitionList"
            :cssList="this.cssList"
            :pickupGroupList="this.pickupGroupList"
            @closeModal="closeModal"
          ></ModalAddLine>
        </DxScrollView>
      </DxPopup>
    </div>
  </div>
</template>
<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/plugins/common-lib';
  import { DxPopup } from 'devextreme-vue/popup';
  import ModalAddLine from './modal-add-line.vue';
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
      DxPopup,
      ModalAddLine,
      DxScrollView,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            cm: null,
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          title: this.$_msgContents('UC.WORD.LINE_ADD'),
          width: '60%',
          height: '650',
        },
        cmList: [],
        partitionList: [],
        pickupGroupList: [],
        cssList: [],
        dataGrid: {
          excel: {
            title: this.$_msgContents('UC.WORD.LINE_INFO', { defaultValue: '회선 정보' }),
          },
          refName: 'lineGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          height: 'calc(100vh - 350px)', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            // cellPrepared: true,
          },
          showActionButtons: {
            excel: true, // 엑셀 버튼
            customButtons: [],
          },
          isDuplicateConfigKey: false, //설정키 중복 체크
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: true,
            sorting: true,
            grouping: true,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                if (rowData?.cmNm) {
                  return this.getCmNm(rowData.cmNm);
                }
                if (rowData.cmNm == null || rowData.cmNm == 'null') {
                  return '';
                }
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
            },

            {
              caption: '표시(경고이름)',
              i18n: 'UC.WORD.ALERTINGNAME',
              dataField: 'alertingname',
              // width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '발신검색공간',
              i18n: 'UC.WORD.CSS',
              dataField: 'css',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },

            {
              caption: '당겨받기그룹',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'callpickupgroupname',
              width: 200,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '외부통화제어',
              i18n: 'UC.WORD.EXTERNALCALLCONTROLPROFILE',
              dataField: 'externalcallcontrolprofile',
              width: 200,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: '수정일',
              i18n: 'COMPONENTS.MODIFY_DATE',
              dataField: 'editDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getCmList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;
        const result = this.$_getCode('search_type_device_cm').filter(d => d.delFl === 'N');
        const svrInx = params?.['cm'] ? params['cm'] : result[0].codeValue;
        this.setCustomTypes('cm', svrInx);
        return result;
      },
    },
    methods: {
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
      },
      getCustomButtons() {
        const buttons = [
          {
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.DELETE'),
              elementAttr: { class: 'btn_XS white light_filled trash' },
              // width: 60,
              height: 30,
              onClick: () => {
                this.onDeleteLine();
              },
            },
            location: 'before',
          },
        ];

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_msgContents('COMPONENTS.ADD'),
            elementAttr: { class: 'btn_XS default filled ' },
            // width: 60,
            height: 30,
            onClick: () => {
              this.isOpenModal(true);
            },
          },
          location: 'before',
        });

        buttons.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: this.$_msgContents('UC.WORD.LINE_SYNC'),
            elementAttr: { class: 'btn_XS default filled ' },
            // width: 100,
            height: 30,
            onClick: () => {
              this.onSyncLine();
            },
          },
          location: 'before',
        });
        return buttons;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      async mountData() {
        await this.selectCmList();
        await this.selectPartitionList();
        await this.selectCssList();
        await this.selectPickupGroupList();
        await this.selectDataList();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = 'regDt,id') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }
            // let currentpage = this.$refs.lineGrid.getGridInstance.pageIndex();
            // let pagesize = this.$refs.lineGrid.getGridInstance.pageSize();
            params = { ...params, ...this.searchType.paramsData, ...this.searchType.customTypes };
            // currentpage: currentpage + 1,
            // pagesize: pagesize,
            /**검색조건이 0(전체) 일경우 파라미터 삭제 */
            if (params?.['cm'] === '0') {
              delete params['cm'];
            }
            const payload = {
              actionname: 'EUC_LINE_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /**@description 교환기 목록 가져오는 메서드 */
      async selectCmList(sort = '+cmOrd') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CM_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          const length = res.data.data?.length;
          if (length > 0) {
            this.cmList = res.data.data;
            for (let i = 0; i < this.cmList.length; i++) {
              delete this.cmList[i].id;
              delete this.cmList[i].cmIp;
              delete this.cmList[i].passwd;
              delete this.cmList[i].pinPasswd;
              delete this.cmList[i].regDt;
              delete this.cmList[i].siteId;
              delete this.cmList[i].tenantId;
              delete this.cmList[i].userId;
              delete this.cmList[i].vender;
              delete this.cmList[i].viewCd;
            }
          }
        }
      },
      /**@description 파티션 목록 가져오는 메서드 */
      async selectPartitionList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_PARTITION_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.partitionList = res.data.data;
          }
        }
      },
      /**@description 발신검색 목록 가져오는 메서드 */
      async selectCssList(sort = 'regDt,id') {
        const params = { sort: sort, viewFl: 'Y' };
        const payload = {
          actionname: 'EUC_CSS_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.cssList = res.data.data;
          }
        }
      },
      /**@description 당겨받기 목록 가져오는 메서드 */
      async selectPickupGroupList(sort = 'regDt,id') {
        let params = { sort: sort, viewFl: 'Y' };

        const payload = {
          actionname: 'EUC_PICKUPGROUP_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            this.pickupGroupList = res.data.data;
          }
        }
      },
      /**@description 내선삭제 메서드 */
      async onDeleteLine() {
        const selectedRowsData = this.$refs.lineGrid.selectedRowsData;
        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        } else {
          let flag = true;
          let prevCm = '';
          for (let i = 0; i < selectedRowsData.length; i++) {
            if (i === 0) {
              prevCm = selectedRowsData[i].cmNm;
              continue;
            }
            if (prevCm !== selectedRowsData[i].cmNm) {
              flag = false;
            }
            prevCm = selectedRowsData[i].cmNm;
          }

          if (!flag) {
            await this.$_Msg(this.$_msgContents('UC.MESSAGE.CHECK_EXCHANGER_TYPE'));
            return;
          }
          const parseData = selectedRowsData.map(item => {
            return { pattern: item.pattern, routepartitionname: item.routepartitionname };
          });

          let params = {
            cmNm: prevCm,
            loginId: this.$store.getters.getLoginId,
            linelist: parseData,
          };
          const payload = {
            actionname: 'EUC_LINE_DELETE',
            data: params,
            loading: true,
          };

          let confirm = this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE');

          if (!(await this.$_Confirm(confirm))) {
            return;
          }
          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.lineGrid.getGridInstance.clearSelection();
            await this.$refs.lineGrid.getGridInstance.refresh();
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      },
      /** @description 전화기 동기화 메서드*/
      async onSyncLine() {
        let confirm = this.$_msgContents('UC.MESSAGE.START_SYNC');

        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        for (const item of this.cmList) {
          const params = { cmNm: item.cmNm, loginId: this.$store.getters.getLoginId };
          // const params = { cmNm: 'HQ', loginId: this.$store.getters.getLoginId };
          const payload = {
            actionname: 'EUC_LINE_SYNC',
            data: params,
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            // await this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
            await this.$refs.lineGrid.getGridInstance.refresh();
          } else {
            if (res.data.data.length == 0) {
              await this.$_Msg(res.data.header.resMsg);
            } else {
              await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
            }
          }
        }
      },
      /** @description 교환기 이름 한글 변환 메서드
       *  @param {String} cm Name
       *  @returns {String} 한글명
       */
      getCmNm(cm) {
        let cmNm = '';
        for (let i = 0; i < this.cmList.length; i++) {
          if (this.cmList[i].cmNm === cm) {
            cmNm = this.cmList[i].description;
          }
        }
        if (cmNm !== '' && cmNm !== null) {
          return cmNm;
        } else {
          return '';
        }
      },
      /**@description : 내선관리 모달 Open/Close */
      isOpenModal(bool) {
        this.modal.isOpened = bool;
      },
      /**@description : 내선관리 모달 close 콜백함수 */
      async closeModal(flag) {
        if (flag) {
          this.isOpenModal(false);
          // await this.selectDataList();
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
